<template>
  <div class="mb-100">
    <div class="divider-full"></div>
    <!-- <div class="container-fluid section-head">
      <div class="container-standar container-res-1440">
        <span class="pt-6 pb-6 text-white font-weight-6 font-24 d-inline-block">Visa</span>
      </div>
    </div> -->
    <div class="cotainer-standar container-res-1440 container-tabungan text-center">
      <div class="container-standar mb-10 pad-20">
        <div class="row">
          <div class="col-md-8">               
            <div class="img-tabungan-sv">
              <iframe frameborder="0" height="80%" width="95%" style="display:none"
                src="https://www.youtube.com/embed/62ocJLsqWeE?autoplay=1&controls=0&showinfo=0&autohide=1">
              </iframe>  
              <a href="https://play.google.com/store/apps/details?id=com.teameite.tabunganumroh" class="d-block mt-2 mb-20 width-95 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white">Download Aplikasi Tabungan</a>
            </div> 
          </div>
          <div class="col-lg-4 pad-20 berita-content card shadow">        
            <div v-if="!LoginCheck">
              <div class="text-left pad-10"><!--  card shadow  -->
                <span class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-16 text-primary simulasi-label"
                 @click.prevent="onclickSimulasi"
                 v-bind:class="{'font-weight-7': showSimulasiTabungan, 'width-100':!userData, 'font-weight':700}">Simulasi Tabungan 
                </span>
                <!-- <span class="text-primary bor-bot-1 pt-2 pb-2 width-45 font-16 d-inline-block text-right cursor-pointer tabungan-label"
                  @click.prevent="onclickListTabungan"
                  v-bind:class="{'font-weight-7':showDetailTabungan || showListTabungan, 'font-weight':700}"
                  v-if="userData != null">{{labelTrxTabungan}}
                </span> -->

                <!-- List Tabungan Start -->
                <div class="tabungan-list form-group mt-3" v-show="showListTabungan">
                  <div class="card text-left pad-10" v-if="dataListTabungan != ''">
                    <div class="form-group d-inline-block bor-bot-1" :key="i" v-for="(items, i) in dataListTabungan">
                      <div class="pb-2">
                        <span class="float-left">Jenis Paket</span>
                        <span class="float-right font-weight-4 text-primary">{{ items.nama_paket }}</span> <br>
                      </div>
                      <div>
                        <span class="float-left">Saldo Tabungan</span>
                        <span class="float-right font-weight-4 text-primary">{{ items.mutasi_data | numeralFormat('0,0[.]00') }}</span> <br>
                      </div>
                      <span class="float-left font-weight-4 pt-1 width-90 text-primary font-12 cursor-pointer" @click.prevent="getDataTabunganDetail(items.id_tabungan, items.id_paket)">Lihat Detail
                      </span>
                      <span class="d-inline-block font-17 width-10 text-right text-primary cursor-pointer" @click.prevent="getDataTabunganDetail(items.id_tabungan, items.id_paket)"><i class="fa fa-angle-double-right"></i></span>
                     </div>
                  </div>
                  <div class="card text-left pad-10" v-else>
                    <div class="form-group d-inline-block text-center">
                      <div class="pb-2">
                        <span>Saat ini, anda belum memiliki tabungan.</span>
                      </div>
                      <div class="text-center">
                        <span
                          class="d-block w-100 mb-6 cursor-pointer bg-success text-white text-center pad-7 border-radius-5"
                          @click.prevent="onclickSimulasi"
                        >Ya, Buat Tabungan.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- List tabungan end -->

                <!-- Detail tabungan start -->
                <div class="tabungan-list form-group mt-3" v-show="showDetailTabungan">
                  <div class="card text-left pad-10">
                    <div class="form-group d-inline-block no-more-tables">
                      <div class="pb-2 pl-2 col-12">
                        <span class="font-weight-5 font-13 text-primary">{{namaPaketDetail.toUpperCase()}}</span> <br>
                      </div>
                      <table class="table table-borderless">
                        <thead class="cf">
                          <tr>
                            <th class="font-weight-5 font-13 text-default" scope="col">Tanggal</th>
                            <th class="font-weight-5 font-13 text-default" scope="col">Invoice</th>
                            <th class="font-weight-5 font-13 text-default text-right" scope="col">Nominal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr :key="i" v-for="(ddt, i) in dataDetailTransaksi">
                            <td class="font-weight-5 font-12 text-primary" :data-label="'Tanggal'">{{getDate(ddt.tanggal_trx)}}</td>
                            <td class="font-weight-5 font-12 text-primary" :data-label="'Invoice'">{{ddt.invoice_number}}</td>
                            <td class="font-weight-5 font-12 text-primary text-right" :data-label="'Nominal'">{{ddt.jumlah | numeralFormat('0,0[.]00')}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr style="border-top: 2px solid #23a55b;">
                            <td class="font-weight-6 font-12 text-primary footer-label">Total Tabungan</td>
                            <td class="font-weight-6 font-12 text-primary text-right" :data-label="'Total Tabungan'" colspan="2">{{totalJumlahTabungan | numeralFormat('0,0[.]00')}}</td>
                          </tr>
                          <tr style="border-top: 2px solid #23a55b;" v-if="invoicePending != ''">
                            <td class="font-weight-6 font-12 text-warning" :data-label="'Status'">Menunggu Pelunasan</td>
                            <td class="font-weight-6 font-12 text-danger" :data-label="'Invoice'">{{invoicePending.invoice_number}}</td>
                            <td class="font-weight-6 font-12 text-danger text-right" :data-label="'Nominal'">{{invoicePending.jumlah | numeralFormat('0,0[.]00')}}</td>
                          </tr>
                        </tfoot>
                      </table>
                      <span class="d-block w-100 mb-6 cursor-pointer bg-primary text-white text-center pad-7 border-radius-5"
                        @click.prevent="onclickListTabungan"
                      ><i class="fa fa-angle-double-left"></i> Kembali</span>
                      <span class="d-block w-100 mb-6 cursor-pointer bg-warning text-white text-center pad-7 border-radius-5"
                        @click.prevent="onTambahTabungan"
                      >Tambah Tabungan</span>
                    </div>
                  </div>
                </div>
                <!-- Detail tabungan end -->

                <!-- Form simulasi tabungan start -->
                <div class="form-simulasi" v-show="showSimulasiTabungan">
                  <div class="form-group mt-3">
                    <label
                      for="destination"
                      class="text-primary font-14"
                    >Pilih Paket Tabungan</label>
                    <v-select
                      class="caret-icon"
                      :options="sortPaket"
                      label="name"
                      v-model="paketLayanan"
                      :value="sortPaket.id"
                      v-validate="'required'"
                      name="paket"
                      placeholder="Pilih Paket Tabungan"
                    ></v-select>
                    <span class="eror text-danger font-10">{{errors.first('paket')}}</span>
                  </div>
                  <div class="group-field">
                      <label for="filter" class="text-primary font-14">Jumlah Jamaah</label>
                      <div  class="icon-date" >
                        <input
                          type="number"
                          v-validate="'required'"                        
                          v-model="jumlahJamaahStr"
                          name="jamaah"
                          min="1"
                          max="10"
                          class="input-same-select pos-relative "
                        />
                          <span class="eror text-danger font-10" >{{errors.first('Jamaah')}}</span>
                        <label class="label-jamaah" > Jamaah </label>
                      </div>
                  </div>
                  <div class="row">
                    <div class="form-group mt-3 col-6">
                      <label
                        for="anyDes"
                        class="text-primary font-14"
                      >Target Saldo</label>
                      <div class="input-group">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">Rp</span>
                        </div>
                        <input
                          type="text"
                          name="targetsaldo"
                          class="form-control"
                          :value="changePricePax(targetSaldo) | numeralFormat('0,0[.]00')"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="form-group mt-3 col-6">
                      <label
                        for="anyDes"
                        class="text-primary font-14"
                      >Setoran Perbulan</label>
                      <div class="input-group">
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">Rp</span>
                        </div>
                        <input
                          type="text"
                          autocomplete="off"
                          name="setoran"
                          class="form-control"
                          v-model="setoranPerbulan"
                        />
                        <span class="d-block w-100 text-danger font-12" v-show="showTextNom">{{messageText}}</span>
                      </div>
                    </div>
                    <div class="form-group col-6">
                      <label for="anyDes" class="text-primary font-14">Estimasi Keberangkatan</label>
                    </div>
                    <div class="form-group col-6 text-right">
                      <label for="anyDes" class="font-14">
                        <span v-if="estimasiDepart != ''">{{estimasiDepart}}</span>
                        <span v-else>-</span>
                      </label>
                    </div>
                  </div>
                  <div class="row form-group pt-4 pb-4 pad-10">
                    <!-- <span
                      @click="Validation()" v-if="estimasiDepart != ''"
                      class="d-block w-100 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white"
                    >Selanjutnya</span> -->
                    <a v-if="estimasiDepart != ''" href="https://play.google.com/store/apps/details?id=com.teameite.tabunganumroh" class="d-block w-100 bg-warning text-center pad-15 border-radius-5 cursor-pointer text-white">Download Aplikasi Tabungan</a>
                    <span
                      @click="cekEstimasiFunction()" v-else
                      class="d-block w-100 bg-success text-center pad-15 border-radius-5 cursor-pointer text-white"
                    >Cek Estimasi</span>
                  </div>
                </div>
                <!-- Form simulasi tabungan end -->
              </div>
            </div>
            <div v-else>
              <div class="card text-left pad-10"> <!-- card shadow -->
                <span
                  class="d-inline-block bor-bot-1 width-100 pt-2 pb-2 font-18 font-weight-7 text-primary"
                >Review Pemesanan</span>
                <div class="form-group d-inline-block mt-4">
                  <span class="float-left">Nama Pemesan</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.name }}</span>
                </div>
                <div class="form-group d-inline-block">
                  <span class="float-left">Email</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.email }}</span>
                </div>
                <div class="form-group d-inline-block">
                  <span class="float-left">No Telp/HP</span>
                  <span class="float-right font-weight-6 text-primary">{{ userData.phone }}</span>
                </div>

                <div class="form-group d-inline-block">
                  <span class="float-left">Jumlah Jamaah</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>{{ jumlahJamaahStr }}</span> PAX
                  </span>
                </div>

                <div class="form-group d-inline-block">
                  <span class="float-left">Harga Paket</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ hargaPerpax | numeralFormat('0,0[.]00')}}
                  </span>
                </div>

                <div class="form-group d-inline-block bg-danger-light pt-3 pb-3 pl-2 pr-2">
                  <span class="float-left font-weight-6">Target Saldo</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ targetSaldo | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block bg-danger-light pt-3 pb-3 pl-2 pr-2">
                  <span class="float-left font-weight-6">Setoran perbulan</span>
                  <span class="float-right font-weight-6 text-primary">
                    <span>IDR.</span>
                    {{ setoranPerbulanStr | numeralFormat('0,0[.]00') }}
                  </span>
                </div>

                <div class="form-group d-inline-block pt-3 pb-3 pl-2 pr-2">
                  <span
                    class="d-block w-100 mb-6 cursor-pointer bg-primary text-white text-center pad-7 border-radius-5"
                    @click.prevent="backTo()"
                  >Kembali</span>

                  <span
                    class="d-block w-100 bg-warning cursor-pointer text-white text-center pad-7 border-radius-5"
                    @click.prevent="OpenPayment()"
                  >Bayar</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-lg-5">
          </div> -->
        </div>
      </div>
    </div>
    <div class="container-standar container-res-1440">
      <div class="container-standar mt-10">
        <div class="row">
          <div class="row">
            <div class="col-md-12 mb-40 text-center">
              <span class="d-block margin-auto font-20 text-dark mb-10 font-weight-6">Keuntungan Menggunakan Tabungan Umroh Realtravel?</span>
              <span class="d-block margin-auto font-14 text-grey">Tabungan Umroh menjadi solusi tepat buat kamu yang ingin mewujudkan impian umroh. 
                Kamu pastinya bisa menabung sesuai dengan kebutuhan kamu loh! 
                Hal itu tentu saja sangat membantu kamu yang ingin umroh dengan mudah tanpa pusing mikirin biaya yang langsung mahal. 
                Ayo buruan nabung di tabungan Umroh, kesempatan gak datang dua kali.</span>
            </div>
            
            <div class="col-6 col-lg-4 text-center mb-5">
              <img
                src="@/assets/images/icon/a.png"
                alt
                class="margin-auto"
                width="225"
                height="185"
              />
              <span class="d-block margin-auto font-20 text-dark mb-10 font-weight-6">Tabungan Fleksibel</span>
              <span
                class="d-block margin-auto font-14 text-grey"
              >Kamu bisa mengatur sendiri jumlah dana yang akan kamu sisihkan untuk menabung setiap bulannya sesuai dengan keinginan kamu.</span>
            </div>

            <div class="col-6 col-lg-4 text-center mb-5">
              <img
                src="@/assets/images/icon/d.png"
                alt
                class="margin-auto"
                width="225"
                height="185"
              />
              <span
                class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
              >Dijamin Berangkat</span>
              <span
                class="d-block margin-auto font-14 text-grey"
              >Jika tabungan kamu sudah mencapai target, berbagai paket pilihan terbaik dapat kamu pilih sesuai dengan saldo umroh yang telah kamu kumpulkan.</span>
            </div>

            <div class="col-6 col-lg-4 text-center mb-5">
              <img
                src="@/assets/images/icon/b.png"
                alt
                class="margin-auto"
                width="225"
                height="185"
              />
              <span
                class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
              >Aman dan Amanah</span>
              <span
                class="d-block margin-auto font-14 text-grey"
              >Dana yang kamu kumpulkan tersimpan kuat di bank BNI, tidak ada pihak manapun yang dapat menyentuhnya sampai kamu berangkat nanti.</span>
            </div>
            <div class="col-6 col-lg-4 text-center mb-5">
              <img
                src="@/assets/images/icon/e.png"
                alt
                class="margin-auto"
                width="225"
                height="185"
              />
              <span
                class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
              >Virtual Account Jamaah</span>
              <span
                class="d-block margin-auto font-14 text-grey"
              >Setiap jamaah yang terdaftar melalui aplikasi akan diberikan no virtual akun dari bank BNI, kapanpun dimanapun kamu bisa mengaksesnya.</span>
            </div>

            <div class="col-6 col-lg-4 text-center mb-5">
              <img
                src="@/assets/images/icon/c.png"
                alt
                class="margin-auto"
                width="225"
                height="185"
              />
              <span class="d-block margin-auto font-20 text-dark mb-10 font-weight-6">Mudah Cek Saldo</span>
              <span
                class="d-block margin-auto font-14 text-grey"
              >Kamu bisa mengecek sendiri jumlah saldo tabungan, bisa melalui aplikasi tabungan atau melalui QR CODE yang sudah kami sediakan.</span>
            </div>
            
            <div class="col-6 col-lg-4 text-center mb-5">
              <img
                src="@/assets/images/icon/f.png"
                alt
                class="margin-auto"
                width="225"
                height="185"
              />
              <span
                class="d-block margin-auto font-20 text-dark mb-10 font-weight-6"
              >Notifikasi via Whatsapp</span>
              <span
                class="d-block margin-auto font-14 text-grey"
              >Setiap kali kamu menabung, kami akan mengirimkan pemberitahuan / notifikasi ke no whatsapp yang terdaftar.</span>
            </div>
            
          </div>

        </div>
      </div>
      
      <div class="container">
        <div class="container mt-10">

            <div class="row">
              <div class="col-md-12 mt-22">
                <span class="font-30 font-weight-30 text-dark text-center d-inline-block width-100">FAQ</span>
                <span class="font-16 font-weight-7 text-grey text-center d-inline-block width-100">Daftar pertanyaan yang sering diajukan</span>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-6 mt-5">

                <template>
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-1 variant="light" class="text-left">Apa itu tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-1" visible accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Sebuah program merencanakan umroh, haji, dan wisata dengan sistem menabung terlebih dahulu.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-2 variant="light" class="text-left">Apa saja syarat membuka tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-2" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Untuk membuka tabungan, kamu hanya perlu menyiapkan photo <b class="text-info">KTP</b> dan No <b class="text-info">WhatsApp</b> yang masih aktif.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-3 variant="light" class="text-left">Bagaimana cara menambah saldo tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-3" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Untuk menambah saldo tabungan kamu bisa melakukan berbagai macam cara seperti <b>Bank Transfer</b>, <b>Virtual Account</b>, atau melalui <b>Minimarket</b> terdekat. Nominal tabungan bisa disesuaikan dengan kemampuan kamu bisa perhari, perpekan ataupun perbulan.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-4 variant="light" class="text-left">Jika saldonya lebih dari harga paket, bagaimana ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-4" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Jika nominal tabungan melebihi dari harga paket yang dipilih maka kelebihannya akan dikembalikan. Demikian pula sebaliknya, jika nominal tabungan kurang dari harga paket yang dipilih, maka berkewajiban menambahkan selisihnya.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.tabungan-5 variant="light" class="text-left">Bagaimana jika saya mau membatalkan tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="tabungan-5" accordion="my-accordion1" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                          <ul class="list">
                            <li>1. Calon Jemaah <strong>membuat Surat Pernyataan pembatalan</strong> tabungan disertai dengan alasannya</li>
                            <li>2. Penarikan atas saldo rekening hanya dapat <strong>dilakukan di Kantor Cabang</strong> pembuka</li>
                            <li>3. Jumlah saldo yang harus disisakan setelah penarikan sebesar <strong>Rp. 500.000,- yang merupakan saldo minimum</strong></li>
                            <li>4. Saldo minimum yang tersimpan dalam Rekening Virtual dinyatakan masih <strong>tetap aktif sampai batas waktu 3 tahun</strong> dihitung dari tanggal penandatanganan Surat Pernyataan Pembatalan</li>
                            <li>5. Saldo minimum yang tersimpan dalam Rekening Virtual <strong>bisa dialihkan kepada ahli waris</strong> atau kepada keluarga yang ditunjuk, selama rekening Virtualnya masih aktif.</li>
                          </ul>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                  </div>
                </template>

              </div>
              <div class="col-12 col-lg-6 mt-5">

                <template>
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-1 variant="light" class="text-left">Berapa besar biaya awal pembukaan tabungan ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-1" visible accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Untuk mengikuti program tabungan, kamu harus melakukan setoran awal sebesar <b>Rp 500.000</b> (lima ratus ribu rupiah).</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-2 variant="light" class="text-left">Berapa besar target tabungan agar bisa berangkat ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-2" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text><ol>
                            <li>1. Umroh Reguler sebesar <b>Rp 25.000.000</b></li>
                            <li>2. Umroh VIP sebesar <b>Rp 30.000.000</b></li>
                            <li>3. Paket Wisata sebesar <b>Rp 2.000.000</b></li>
                          </ol></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-3 variant="light" class="text-left">Berapa besar setoran perbulan yang harus saya bayarkan ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-3" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Kamu bebas menentukan berapa besar setoran perbulan disesuaikan dengan kemampuan masing-masing dan rencana keberangkatan.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-4 variant="light" class="text-left">Apakah dana saya aman ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-4" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Dana kamu sangat aman tersimpan kuat di <b>Bank BNI</b>, kami sudah bekerjasama dan terintegrasi dengan Bank BNI. Sehingga dana kamu sepenuhnya ada di Bank BNI, bukan di kami, dan kamu akan mendapatkan nomor Virtual Account sendiri dan bisa melakukan pengecekan melalui barcode secara realtime.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.dana-5 variant="light" class="text-left">Bagaimana proses pengembalian dana saya ?</b-button>
                      </b-card-header>
                      <b-collapse id="dana-5" accordion="my-accordion2" role="tabpanel">
                        <b-card-body>
                          <b-card-text>Proses pengembalian dana akan dilakukan setelah semua persyaratan pembatalan dipenuhi. Dana akan di kembalikan melalui metode transfer Bank.</b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>

                  </div>
                </template>

              </div>
            </div>


        </div>
      </div>


    </div>
    <b-modal id="modal-Success" hide-header hide-footer no-close-on-backdrop centered title>
      <div class="col-md-12 pb-6 text-center">
        <img width="150px" class="margin-auto pb-10 pt-4" src="@/assets/images/icon/check.svg" alt />
        <span class="text-primary font-weight-6 font-18">Pesanan anda sedang kami Proses</span>
        <br />
        <span class="text-grey font-weight-5 font-16 d-block mb-15">Silahkan Lakukan Pembayaran</span>
        <span
          class="d-block width-50 bg-warning pt-2 mb-10 pb-2 font-weight-6 text-white border-radius-5 margin-auto cursor-pointer"
          @click="PostDataTabungan(trasaction_inv)"
        >Bayar</span>       
        <span @click.prevent="onclickListTabungan"
          class="d-block width-50 mt-10 bc-warning border-solid-2 pt-2 pb-2 text-warning font-weight-6 border-radius-5 margin-auto cursor-pointer"
        >Lihat Data Tabungan</span>      
      </div>
    </b-modal>

    <!-- Modal tambah tabungan start -->
    <b-modal id="modal-tambah-pembayaran" title="Silahkan masukan nominal" hide-footer no-close-on-backdrop centered>
      <div class="col-md-12 pb-6 text-center">
        <div class="form-group mt-1">
            <input autocomplete="off" type="text" placeholder="Contoh: Rp. 500.000" 
            v-model="setoranPerbulan" 
            class="input-same-select pos-relative pristine touched is-valid" data-vv-id="3" aria-required="true" aria-invalid="false">
            <div v-show="showTextNom">
              <span class="d-block w-100 text-danger font-12">{{messageText}}</span>
            </div>
        </div>
        <span @click.prevent="postTambahTabungan"
          class="d-block width-50 bg-warning pt-2 pb-2 font-weight-6 text-white border-radius-5 margin-auto cursor-pointer"
        >Lanjutkan pembayaran</span>
      </div>
    </b-modal>
    <!-- Modal tambah tabungan end -->

    <b-modal id="modal-Payment" scrollable    hide-footer no-close-on-backdrop centered  >
    <template v-slot:modal-title>
     <span class="d-block font-18 text-primary font-weight-7">Pilih Metode Pembayaran</span>
    </template>
        <payment :dataBinding='visaNumber'></payment>
 
    </b-modal>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `
      }
    }
  }
</script>
<script>
import payment from "@/views/components/payment.vue";
import * as moment from "moment";
import "@/assets/scss/components/_tabungan.scss";
// import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    // SocialSharing
  payment,
    // flatPickr
  },
  data() {
    return {
      LoginCheck: false,
      paymentURL:null,
      userData: this.$store.state.userdata,
      submitted:false,
      text: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf.",
      date: new Date(),
      config: {
        mode: "single",
        minDate: "today",
        dateFormat: "D d-M-Y"
      },
      visaNumber:null,
      jumlahJamaah: [
        { id: 1, text: "1 Jamaah" },
        { id: 2, text: "2 Jamaah" },
        { id: 3, text: "3 Jamaah" },
        { id: 4, text: "4 Jamaah" },
        { id: 5, text: "5 Jamaah" },
        { id: 6, text: "6 Jamaah" },
        { id: 7, text: "7 Jamaah" },
        { id: 8, text: "8 Jamaah" },
        { id: 9, text: "9 Jamaah" },
        { id: 10, text: "10 Jamaah" }
      ],
      jumlahJamaahStr:1,
      sortPaket: [
        { id: 1, name: "Paket Umroh Reguler", harga:25000000 },
        { id: 2, name: "Paket Umroh VIP", harga:30000000 },
        { id: 3, name: "Paket Tour", harga:2000000 }
      ],
      paketLayanan:'',
      targetSaldo:null,
      setoranPerbulan:null,
      setoranPerbulanStr:null,
      hargaPerpax:null,
      totalPax:null,
      dataSection: null,
      showTextNom:false,
      messageText:'',
      trasaction_inv:null,
      showListTabungan:false,
      showDetailTabungan:false,
      showSimulasiTabungan:true,
      dataListTabungan:[],
      dataDetailTransaksi:[],
      invoicePending:{},
      idTabunganDetail:'',
      namaPaketDetail:'',
      totalJumlahTabungan:0,
      estimasiDepart: '',
      labelTrxTabungan:'List Tabungan'
    };
  },
  watch: {
    paketLayanan(val) {
      this.hargaPerpax = val.harga;
      this.dataSection = val
    },    
    setoranPerbulan(val) {
      let result = val.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      this.$emit('input', val.replace(/[^0-9]/g, ''))
      this.$nextTick(() => this.setoranPerbulan = result)
      this.estimasiDepart = '';

      this.setoranPerbulanStr = (this.setoranPerbulan == null) ? 0 : this.setoranPerbulan.split('.').join('');
      if (this.setoranPerbulanStr < 500000) {
        this.messageText = '*Minimal setoran Rp 500.000';
        this.showTextNom = true;
      } else {
        this.messageText = '';
        this.showTextNom = false;
      }
    },
  },
  mounted() {
    this.$store.commit("SET_BG", false);
    this.getDataTabunganList();
  },
  methods: {
    cekEstimasiFunction(){
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.setoranPerbulanStr = (this.setoranPerbulan == null) ? 0 : this.setoranPerbulan.split('.').join('');
          // this.paketLayanan = this.sortPaket[0].text;
          var dNow = new Date();
          var hasil = this.targetSaldo / this.setoranPerbulanStr
          
          moment.locale('ID'); 
          if (this.setoranPerbulanStr < 500000) {
            this.messageText = '*Minimal setoran Rp 500.000';
            this.showTextNom = true;
          } else {
            this.messageText = '';
            this.showTextNom = false;        
            dNow.setMonth(dNow.getMonth() + hasil);
            this.estimasiDepart = moment(dNow).format('MMMM, YYYY');
            console.log(this.estimasiDepart);
          }

        }
      });
    },
    onclickSimulasi(){
      this.showListTabungan = false
      this.showSimulasiTabungan = true
      this.showDetailTabungan   = false
      this.labelTrxTabungan = 'List Tabungan'
    },
    onclickListTabungan(){
      this.showListTabungan = true
      this.showSimulasiTabungan = false
      this.showDetailTabungan   = false
      this.LoginCheck = false
      this.labelTrxTabungan = 'List Tabungan'
      this.$bvModal.hide("modal-Success");
    },
    getDataTabunganList(){
      let param = new FormData();
      if (this.userData != null) {
        param.append("api_key", "2222");
        param.append("format", "");
        param.append("ws", "tabungan"); 
        param.append("call", "list_tabungan");
        param.append("id_tabungan", '');  
        param.append("partner_id", this.$store.state.userdata.partner_id);
        this.axios
          .post("https://apitiket.realtravel.co.id/ws", param, {
              headers: new Headers({
                accept: "application/json",
                "Content-Type": "multipart/form-data, text/plain"
              })
            })
          .then(response => {
            let res = response.data
            if (res.rest_no == 0) {
              let dataMutasi = res.data.mutasi;
              // this.showListTabungan = true;
              // this.showSimulasiTabungan = false;
              // this.showDetailTabungan = false;
              // this.labelTrxTabungan = 'List Tabungan'
              this.dataListTabungan = []
              for (let i = 0; i < dataMutasi.length; i++) {
                let namaPaket = '';
                if (dataMutasi[i].id_paket == 'cEdyczdWQmU3a0U0VVlyQU90aXVDdz09') {
                  namaPaket = "Paket Umroh Reguler";
                } else if (dataMutasi[i].id_paket == 'dExHbEJVYzdwZWwxYys4ZVJGck92UT09') {
                  namaPaket = "Paket Umroh VIP";
                } else {
                  namaPaket = "Paket Tour";
                }                        
                this.dataListTabungan.push({
                  id_tabungan : dataMutasi[i].id_tabungan,
                  id_paket    : dataMutasi[i].id_paket,
                  mutasi_data : dataMutasi[i].total_tabungan,
                  nama_paket  : namaPaket
                })
              }
            } else {
              this.dataListTabungan = []
            }
        });
      }
    },
    getDataTabunganDetail(idTabungan, idPaket){
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tabungan"); 
      param.append("call", "list_tabungan");
      param.append("id_tabungan", idTabungan);  
      param.append("partner_id", this.$store.state.userdata.partner_id);
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
        .then(response => {
          let res = response.data
          let dataTransaksi = res.data;
          this.dataDetailTransaksi = []
          this.labelTrxTabungan = 'Detail Tabungan'
          if (res.rest_no == 0) {
            let namaPaket = '';  
            if (idPaket == 'cEdyczdWQmU3a0U0VVlyQU90aXVDdz09') {
              namaPaket = "Paket Umroh Reguler";
            } else if (idPaket == 'dExHbEJVYzdwZWwxYys4ZVJGck92UT09') {
              namaPaket = "Paket Umroh VIP";
            } else {
              namaPaket = "Paket Tour";
            }
            this.idTabunganDetail = idTabungan
            this.namaPaketDetail  = namaPaket
            this.totalJumlahTabungan = dataTransaksi.total_tabungan
            for (let i = 0; i < dataTransaksi.transaksi.length; i++) {
              this.dataDetailTransaksi.push({
                tanggal_trx     : dataTransaksi.transaksi[i].tanggal,
                invoice_number  : dataTransaksi.transaksi[i].invoice_number,
                jumlah          : dataTransaksi.transaksi[i].jumlah
              })
              this.setoranPerbulan = dataTransaksi.transaksi[i].jumlah
            }
            this.invoicePending = (dataTransaksi.invoice_pending.invoice_number == null) ? '' : dataTransaksi.invoice_pending;
            this.showListTabungan     = false
            this.showSimulasiTabungan = false
            this.showDetailTabungan   = true
          } else {
            this.dataDetailTransaksi = []
          }
      });
    },
    bindingData(){
      this.$bvModal.show("modal-Payment");
    },
    OpenPayment(){
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tabungan"); 
      param.append("call", "buat_tabungan");
      param.append("id_tabungan", this.dataSection.id);  
      param.append("partner_id", this.$store.state.userdata.partner_id);  
      param.append("pax", this.jumlahJamaahStr);
      param.append("target_saldo", this.targetSaldo);
      param.append("jumlah_bayar", this.setoranPerbulanStr);  
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
        .then(response => {
          let res = response.data
          if(res.rest_no == 0){ 
            this.trasaction_inv = res.data.invoice_number
            setTimeout(() => {
              this.getDataTabunganList();      
              this.$bvModal.show("modal-Success");
            }, 500);
          }else{
            let msg = '';
            if (this.dataSection.id == 1) {
              msg = '<span class="font-weight-5 font-14 text-warning">Silahkan pilih paket lainnya.<br/>Anda sudah menggunakan layanan Tabungan Umroh Regular.</span>'
            } else if(this.dataSection.id == 2){
              msg = '<span class="font-weight-5 font-14 text-warning">Silahkan pilih paket lainnya.<br/>Anda sudah menggunakan layanan Tabungan Umroh VIP.</span>'              
            } else {
              msg = '<span class="font-weight-5 font-14 text-warning">Silahkan pilih paket lainnya.<br/>Anda sudah menggunakan layanan Tabungan Tour.</span>'
            }
            this.$swal('', msg, "warning")
          }
      });
    },
    PostDataTabungan(newVal){
      this.axios
        .get("https://api.realtravel.co.id/transaction/payment?type=product&invoice_number="+newVal, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
        .then(response => {
          let res = response.data
          if (res.code == 200) {
            window.open(res.response.url, '_blank');        
          }else{
            alert('Payment gagal, Silahkan ulangi lagi beberapa saat.')
          }
      });
    },
    postTambahTabungan(){
      let param = new FormData();
      param.append("api_key", "2222");
      param.append("format", "");
      param.append("ws", "tabungan"); 
      param.append("call", "tambah_tabungan");
      param.append("id_tabungan", this.idTabunganDetail);
      param.append("jumlah_bayar", this.setoranPerbulanStr);  
      this.axios
        .post("https://apitiket.realtravel.co.id/ws", param, {
            headers: new Headers({
              accept: "application/json",
              "Content-Type": "multipart/form-data, text/plain"
            })
          })
        .then(response => {
          let res = response.data
          if(res.rest_no == 0){ 
            this.trasaction_inv = res.data.invoice_number
            setTimeout(() => {              
              this.$bvModal.hide("modal-tambah-pembayaran");
              this.$bvModal.show("modal-Success");
            }, 500);
          }else{
            this.$swal('', '<span class="font-weight-5 font-14 text-warning">'+res.message+'</span>', "warning")
          }
      });
    },
    listtransaction(){
      // window.location.assign('https://realtravel.co.id/transaksi')
      window.location.assign('http://localhost:8080/tabungan')
    }, 
    changePricePax(neVal) {
      this.targetSaldo = this.hargaPerpax * this.jumlahJamaahStr;
      return neVal;
    },
    noBack(){       
        window.location.hash="no-back-button";
        window.location.hash="Again-No-back-button";//again because google chrome don't insert first hash into history
        window.onhashchange=function(){
        window.location.hash="no-back-button";
      }
    },
    Validation() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          this.setoranPerbulanStr = (this.setoranPerbulan == null) ? 0 : this.setoranPerbulan.split('.').join('');
          // this.paketLayanan = this.sortPaket[0].text;
          if (this.setoranPerbulanStr < 500000) {
            this.messageText = '*Minimal setoran Rp 500.000';
            this.showTextNom = true;
          } else {
            if (this.$store.state.userdata !== null) {
              this.LoginCheck = true;         
            } else {
              this.$bvModal.show("modal-login");
            }
          }
        }
      });
    },
    onTambahTabungan(){
      this.$bvModal.show("modal-tambah-pembayaran");
    },
    chesen(val){
      return val.images[0].file
    },
    PaymentTabungan(){
      this.$bvModal.hide("modal-Success");        
      this.axios.get('https://api.realtravel.co.id/transaction/payment?type=product&invoice_number='+this.trasaction_inv)
      .then(response => {
        let res = response.data
        if(res.code == 200){
          this.$bvModal.show("modal-Payment");
        } 
      })
    },
    backTo() {
      // this.paketLayanan = this.sortPaket[0].name;
      this.LoginCheck = false;
    },
    getDate(val){
      moment.locale('ID'); 
      return moment(val).format('LL')
    }
  },
  name: "visa"
};
</script>