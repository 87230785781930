<template>
  <div class="col-lg-12 mb-10">
    <div class="card shadow text-left  ">
     
      <div @click.prevent="dataNumber(12)"  class="shadow cursor-pointer flex  width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/I1.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">BNI</span>
          <br />
          <span class="font-12 text-grey">Bayar dengan BNI Virtual Account</span>
        </div>
      </div>
      <div  @click.prevent="dataNumber(7)"  class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100 mt-2" src="@/assets/images/bank/l2.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">BCA</span>
          <br />
          <span class="font-12 text-grey">Bayar dengan BCA KlikPay</span>
        </div>
      </div>
      <div  @click.prevent="dataNumber(8)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100 mt-2" src="@/assets/images/bank/l3.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">Permata Bank</span>
          <br />
          <span class="font-12 text-grey">Bayar dengan Permata Bank Virtual Account</span>
        </div>
      </div>
      <div @click.prevent="dataNumber(15)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/l4.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">Mandiri</span>
          <br />
          <span class="font-12 text-grey">Bayar dengan Mandiri Virtual Account</span>
        </div>
      </div>
      <div  @click.prevent="dataNumber(9)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/l5.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">Maybank</span>
          <br />
          <span class="font-12 text-grey">Bayar dengan Maybank Virtual Account</span>
        </div>
      </div>
      <div @click.prevent="dataNumber(0)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/l6.svg" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">Bank Transfer</span>
          <br />
          <span class="font-12 text-grey">Bayar dari ATM Bersama, Prima atau Alto</span>
        </div>
      </div>
      <div @click.prevent="dataNumber(6)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/l7.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">CIMB Niaga</span>
          <br />
          <span class="font-12 text-grey">Bayar dengan CIMB Niaga Virtual Account</span>
        </div>
      </div>
      <div  @click.prevent="dataNumber(13)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/l8.svg" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary">Indomaret</span>
          <br />
          <span class="font-12 text-grey">Bayar ke Indomaret terdekat</span>
        </div>
      </div>
      <div @click.prevent="dataNumber(14)" class="shadow cursor-pointer flex mt-11 width-100 payment-setting pad-10">
        <div class="width-20 pad-5">
          <img class="w-100" src="@/assets/images/bank/l9.png" alt />
        </div>
        <div class="title ml-3">
          <span class="font-weight-6 text-primary ">Alfamart</span>
          <br />
          <span class="font-12 text-grey">Bayar ke Alfamart terdekat</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default { 
    props:{
    dataBinding:String,
    },
    data() {
        return {
            
        }
    },
    mounted() {  
 
    },
    methods: {
        dataNumber(id_Val){
            // console.log(this.dataBinding + id_Val)
            if(id_Val !== 0){
                  location.assign('https://api.realtravel.co.id/transaction/payment/duitku_inquiry/product?invoice_number='+this.dataBinding+'&channel_id='+id_Val)

            }else{
                 location.assign('https://api.realtravel.co.id/transaction/payment/transfer?type=product&invoice_number='+this.dataBinding)
            }
        }
    },
}
</script>